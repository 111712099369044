import React, { useEffect } from "react";

import formatLanguage from "./utility";

export default function Me() {
  useEffect(() => {
    document.title = "Neuton Foo | Me";
  }, []);

  return (
    <>
      <h1 className="page-title">Me</h1>
      <div className="content-container card-panel hoverable">
        <p>
          I'm Neuton and I make websites. I can be reached at{" "}
          <a
            href="mailto:neuton.foo@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            neuton.foo@gmail.com
          </a>
          .
        </p>
        <p>Check out my...</p>
        <p>
          <strong>Resume</strong> -{" "}
          <a
            href="https://drive.google.com/file/d/14Spt-Eg3j2XhZheM5QHeHwXG4Uta5h0H/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Foo_Neuton_Resume.pdf
          </a>
        </p>
        <p>
          <strong>GitHub</strong> -{" "}
          <a
            href="https://github.com/neutonfoo/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://github.com/neutonfoo/
          </a>
        </p>
        <p>
          <strong>LinkedIn</strong> -{" "}
          <a
            href="https://www.linkedin.com/in/neutonfoo/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.linkedin.com/in/neutonfoo/
          </a>
        </p>
      </div>
      <h2 className="page-title">Education</h2>
      <div className="content-container card-panel hoverable">
        <table className="resume-table">
          <thead>
            <tr>
              <td colSpan="2">
                <strong>University of Southern California</strong> — Viterbi
                School of Engineering
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                &nbsp;&nbsp;&nbsp;&nbsp;Bachelor of Science, Computer Science
              </td>
              <td>
                <em>Aug 2017 — May 2021</em>
              </td>
            </tr>
            <tr>
              <td>
                &nbsp;&nbsp;&nbsp;&nbsp;Master of Science, Computer Science
              </td>
              <td>
                <em>Aug 2021 — May 2022</em>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col s12 m7">
          <h2 className="page-title">Programming</h2>
          <div className="content-container card-panel hoverable">
            {formatLanguage("Python")}
            {formatLanguage("C++")}
            {formatLanguage("Java")}
            {formatLanguage("Node.js")}
            {formatLanguage("React")}
            {formatLanguage("HTML")}
            {formatLanguage("CSS")}
            {formatLanguage("JavaScript")}
            {formatLanguage("jQuery")}
            {formatLanguage("PHP")}
            {formatLanguage("SQL")}
            {formatLanguage("Linux CLI")}
            {formatLanguage("Docker")}
            {formatLanguage("Kubernetes")}
            {formatLanguage("AWS")}
            {formatLanguage("GCP")}
            {formatLanguage("Machine Learning")}
          </div>
        </div>
        <div className="col s12 m5">
          <h2 className="page-title">Certifications</h2>
          <div className="content-container card-panel hoverable">
            <div className="chip hoverable green white-text">
              AWS Certified Solutions Architect — <em>Associate</em>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col s12 m5">
          <h2 className="page-title">Involvement</h2>
          <div className="content-container card-panel hoverable">
            <div className="chip hoverable purple accent-1 black-text">
              🏳️‍🌈 SF Pride Parade 2019, 2021 — <em>Event Volunteer</em>
            </div>
            <div class="divider margin-y"></div>
            <div className="chip hoverable deep-purple white-text">
              USC Scope
            </div>
            <div className="chip hoverable red accent-4 white-text">
              USC Makers
            </div>
            <div className="chip hoverable blue lighten-4">USC SplashSC</div>
            <div className="chip hoverable cyan lighten-1">
              USC International Students Association
            </div>
            <div className="chip hoverable red accent-2">
              USC Singapore Students Association
            </div>
          </div>
        </div>
        <div className="col s12 m7">
          <h2 className="page-title">Hackathons</h2>
          <div className="content-container card-panel hoverable">
            <div className="chip hoverable red darken-4 white-text">
              TreeHacks 2018 — <em>Hacker</em>
            </div>
            <div className="chip hoverable orange white-text">
              TrojanHacks 2018 — <em>Hacker</em>
            </div>
            <div className="chip hoverable blue white-text">
              Santa Barbara Hacks 2019 — <em>Hacker</em>
            </div>
            <div className="chip hoverable blue white-text">
              LA Hacks 2019 — <em>Hacker</em>
            </div>
            <div className="chip hoverable deep-purple darken-4 white-text">
              AthenaHacks 2019, 2021 — <em>Mentor</em>
            </div>
          </div>
        </div>
      </div>
      <h2 className="page-title">Work Experience</h2>
      <div className="content-container card-panel hoverable">
        <div className="row">
          <table className="work-experience-table">
            <tbody>
              <tr>
                <td className="work-experience-meta">
                  <div className="work-experience-role">
                    <strong>Software Engineering Intern</strong>
                  </div>
                  <small className="work-experience-company">
                    at{" "}
                    <strong>
                      <em>Etsy, Inc.</em>
                    </strong>
                  </small>
                </td>
                <td>Los Angeles, CA</td>
                <td>
                  <em>May 2021</em> — <em>Ongoing</em>
                </td>
              </tr>
              <tr>
                <td colSpan="3" className="work-experience-responsibilities">
                  <ul className="work-experience-list">
                    <li>
                      Assisted in expanding the Klarna installments payment
                      option to the United Kingdom, Spain and Italy
                    </li>
                    <li>
                      Deployed PHP code to support Klarna availability in non-US
                      regions
                    </li>
                    <li>
                      Configured monitoring for Klarna Pay-In-3 payment usage
                      with StatsD, Graphite and Grafana{" "}
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td className="work-experience-meta">
                  <div className="work-experience-role">
                    <strong>DevOps Intern</strong>
                  </div>
                  <small className="work-experience-company">
                    at{" "}
                    <strong>
                      <em>Rakuten Viki</em>
                    </strong>
                  </small>
                </td>
                <td>Singapore</td>
                <td>
                  <em>May 2020</em> — <em>Aug 2020</em>
                </td>
              </tr>
              <tr>
                <td colSpan="3" className="work-experience-responsibilities">
                  <ul className="work-experience-list">
                    <li>
                      Developed a Go Slack bot for the Spinnaker continuous
                      delivery platform
                    </li>
                    <li>
                      Prepared and deployed the Slack bot as a micro service
                      with Helm on Google Kubernetes Engine (GKE)
                    </li>
                    <li>
                      Improved Spinnaker pipeline templates by migrating to
                      Jsonnet based templates for extended customization
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td className="work-experience-meta">
                  <div className="work-experience-role">
                    <strong>Teaching Assistant</strong>
                  </div>
                  <small className="work-experience-company">
                    at{" "}
                    <strong>
                      <em>The University of Southern California</em>
                    </strong>
                  </small>
                </td>
                <td>Los Angeles, CA</td>
                <td>
                  <em>Jan 2019</em> — <em>Ongoing</em>
                </td>
              </tr>
              <tr>
                <td colSpan="3" className="work-experience-responsibilities">
                  <ul className="work-experience-list">
                    <li>
                      Instructing as a Teaching Assistant for{" "}
                      <em>ITP 303: Full-Stack Web Development</em>
                    </li>
                    <li>
                      Introducing students to web technologies including HTML,
                      CSS, JavaScript, jQuery, PHP, AJAX and MySQL
                    </li>
                    <li>
                      Advising students with their assignments and final
                      personal projects for 4 semesters of ~80 students each
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td className="work-experience-meta">
                  <div className="work-experience-role">
                    <strong>Course Producer</strong>
                  </div>
                  <small className="work-experience-company">
                    at{" "}
                    <strong>
                      <em>The University of Southern California</em>
                    </strong>
                  </small>
                </td>
                <td>Los Angeles, CA</td>
                <td>
                  <em>Aug 2019</em> — <em>Ongoing</em>
                </td>
              </tr>
              <tr>
                <td colSpan="3" className="work-experience-responsibilities">
                  <ul className="work-experience-list">
                    <li>
                      Instructing as a Course Producer for{" "}
                      <em>CSCI 102: Fundamentals of Computation</em>
                    </li>
                    <li>
                      Leading lab sections for beginners on the core
                      fundamentals of computer science and programming in C++
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td className="work-experience-meta">
                  <div className="work-experience-role">
                    <strong>Software Engineering Intern</strong>
                  </div>
                  <small className="work-experience-company">
                    at{" "}
                    <strong>
                      <em>Oracle Corporation</em>
                    </strong>
                  </small>
                </td>
                <td>Redwood City, CA</td>
                <td>
                  <em>May 2019</em> — <em>Aug 2019</em>
                </td>
              </tr>
              <tr>
                <td colSpan="3" className="work-experience-responsibilities">
                  <ul className="work-experience-list">
                    <li>
                      Developed a user behavior and click analysis utility to
                      identify optimization points for Oracle Fusion Financials
                    </li>
                    <li>
                      Deployed containerized Python and Shell scripts with
                      Docker on Red Hat Enterprise Linux (Oracle Linux)
                    </li>
                    <li>
                      Prototyped web-based visualization interface with SQL
                      (Oracle Database) on Oracle APEX
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td className="work-experience-meta">
                  <div className="work-experience-role">
                    <strong>Web Development Intern</strong>
                  </div>
                  <small className="work-experience-company">
                    at{" "}
                    <strong>
                      <em>Business Buddy</em>
                    </strong>
                  </small>
                </td>
                <td>Singapore</td>
                <td>
                  <em>Apr 2017</em> — <em>Jul 2017</em>
                </td>
              </tr>
              <tr>
                <td colSpan="3" className="work-experience-responsibilities">
                  <ul className="work-experience-list">
                    <li>
                      Conducted quality assurance on Laravel based PHP web
                      applications for company clients
                    </li>
                    <li>
                      Tested application flow through unit testing and
                      documented bugs on GitHub
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td className="work-experience-meta">
                  <div className="work-experience-role">
                    <strong>Web Developer</strong>
                  </div>
                  <small className="work-experience-company">
                    at{" "}
                    <strong>
                      <em>Scout Real Estate</em>
                    </strong>
                  </small>
                </td>
                <td>Beijing, China</td>
                <td>
                  <em>Dec 2012</em> — <em>June 2013</em>
                </td>
              </tr>
              <tr>
                <td colSpan="3" className="work-experience-responsibilities">
                  <ul className="work-experience-list">
                    <li>
                      Expanded company website’s SEO through adjustments to its
                      internal search feature to increase website traffic
                    </li>
                    <li>
                      Improved user interface and user experience through
                      integration of the Google Maps API to display properties
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
