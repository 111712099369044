import React, { useState, useEffect } from "react";
import M from "materialize-css";
import "materialize-css";
import ProjectsMasonry from "./ProjectsMasonry";

import projects from "./data/projects.json";

export default function Projects() {
  // Assign Projects
  // const [projects] = useState(data);

  // Filter Featured Projects
  const [featuredProjects] = useState(
    projects.filter(({ featured }) => featured)
  );

  useEffect(() => {
    document.title = "Neuton Foo | Projects";

    M.toast({
      html: "Click images to reveal more screenshots!",
      displayLength: 2500,
    });

    M.AutoInit();
  }, []);

  return (
    <>
      <h1 className="page-title">Featured</h1>
      {/* <ProjectsMasonry projects={featuredProjects} featuredProjects={false} /> */}
      <ProjectsMasonry projects={featuredProjects} inFeaturedProjects={true} />
      <h1 className="page-title">Projects</h1>
      {/* <ProjectsMasonry projects={projects} featuredProjects={false} /> */}
      <ProjectsMasonry projects={projects} />
    </>
  );
}
