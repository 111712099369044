import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTools,
  faCameraRetro,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import M from "materialize-css";
import "materialize-css";

export default function Navbar() {
  useEffect(() => {
    M.AutoInit();
  }, []);

  return (
    <nav>
      <div className="nav-wrapper">
        <div className="col s12">
          <NavLink exact id="nav-site-title" className="brand-logo left" to="/">
            <i className="material-icons">polymer</i>
            <span id="site-title">Neuton Foo</span>
          </NavLink>
          <ul className="right hide-on-small-only">
            <li>
              <NavLink
                to="/projects"
                className="hoverable waves-effect btn red"
                activeClassName="pulse darken-2"
              >
                <FontAwesomeIcon className="nav-icon" icon={faTools} /> Projects
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to="/projects"
                className="hoverable waves-effect btn red"
                activeClassName="pulse darken-2"
              >
                <FontAwesomeIcon className="nav-icon" icon={faCode} /> Projects
              </NavLink>
            </li> */}
            <li>
              <NavLink
                to="/photography"
                className="hoverable waves-effect btn teal"
                activeClassName="pulse darken-2"
              >
                <FontAwesomeIcon className="nav-icon" icon={faCameraRetro} />{" "}
                Photography
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/me"
                className="hoverable waves-effect btn blue"
                activeClassName="pulse darken-2"
              >
                <FontAwesomeIcon className="nav-icon" icon={faUser} /> Me
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="fixed-action-btn direction-top hide-on-med-and-up">
        <button className="btn-floating btn-large orange lighten-2">
          <i className="large material-icons">menu</i>
        </button>
        <ul>
          <li>
            <NavLink
              to="/projects"
              className="btn-floating red"
              activeClassName="pulse darken-2"
            >
              <FontAwesomeIcon className="nav-icon" icon={faTools} />
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/photography"
              className="btn-floating teal"
              activeClassName="pulse darken-2"
            >
              <FontAwesomeIcon className="nav-icon" icon={faCameraRetro} />
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/me"
              className="btn-floating blue"
              activeClassName="pulse darken-2"
            >
              <FontAwesomeIcon className="nav-icon" icon={faUser} />
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}
