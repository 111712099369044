import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithubAlt, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faIdBadge } from "@fortawesome/free-solid-svg-icons";

import "./App.scss";

import Navbar from "./Navbar";

import Home from "./Home";
import Photography from "./Photography";
import Projects from "./Projects";
import Me from "./Me";
import Error404 from "./Error404";

function App() {
  return (
    <Router>
      <header>
        <Navbar />
      </header>
      <main>
        <div className="page-container container">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/projects">
              <Projects />
            </Route>
            <Route exact path="/photography">
              <Photography />
            </Route>
            <Route exact path="/me">
              <Me />
            </Route>
            <Route path="*">
              <Error404 />
            </Route>
          </Switch>
        </div>
      </main>
      <footer className="page-footer">
        <div className="footer-copyright">
          <div className="container">
            <div className="row">
              <div className="col s6 m8">
                <div>
                  &copy; 2020 Neuton Foo &#8226;
                  <a
                    href="https://drive.google.com/file/d/1y1lcx_rMlkjBuy32s8Uu6XXunoripKeD/view"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-link"
                  >
                    <FontAwesomeIcon
                      className="footer-icon"
                      // alt="Neuton Foo's GitHub"
                      icon={faIdBadge}
                    />
                  </a>{" "}
                  <a
                    href="https://github.com/neutonfoo"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-link"
                  >
                    <FontAwesomeIcon
                      className="footer-icon"
                      icon={faGithubAlt}
                    />
                  </a>{" "}
                  <a
                    href="https://www.linkedin.com/in/neutonfoo/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-link"
                  >
                    <FontAwesomeIcon
                      className="footer-icon"
                      icon={faLinkedin}
                    />
                  </a>
                  &#8226;{" "}
                  <a href="mailto:neuton.foo@gmail.com">neuton.foo@gmail.com</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Router>
  );
}

export default App;
