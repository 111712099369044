import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools, faMusic } from "@fortawesome/free-solid-svg-icons";

import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import M from "materialize-css";

export default function Home() {
  useEffect(() => {
    document.title = "Neuton Foo";

    M.toast({
      html: "Protip - Change instruments to Pipa!",
      displayLength: 2500,
    });

    M.AutoInit();
  }, []);

  const iframe =
    '<iframe src="https://projects.neutonfoo.com/BBD2/" width="100%" height="550px" style="border:none;"></iframe>';

  return (
    <>
      <p className="center-align">
        <span className="chip hoverable blue-grey darken-4 white-text">
          <FontAwesomeIcon className="nav-icon" icon={faMusic} /> BBD2 Player
        </span>
        - More fun projects on the{" "}
        <NavLink
          to="/projects"
          className="hoverable waves-effect btn-small red"
          activeClassName="pulse darken-2"
        >
          <FontAwesomeIcon className="nav-icon" icon={faTools} /> Projects
        </NavLink>{" "}
        page!
      </p>
      <div className="content-container card-panel hoverable">
        <div dangerouslySetInnerHTML={{ __html: iframe }}></div>
      </div>
    </>
  );
}
