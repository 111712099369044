export default function formatLanguage(language, includeKey = false) {
  let colorClasses;
  let customStyle = {};

  switch (language) {
    case "Dart":
      // colorClasses = "brown-text";
      customStyle.backgroundColor = "#48BFF5";
      break;
    case "Flutter":
      colorClasses = "white-text";
      customStyle.backgroundColor = "#21C2F0";
      break;
    case "Rust":
      colorClasses = "brown lighten-1 white-text";
      break;
    case "Arduino":
      colorClasses = "teal lighten-1 white-text";
      break;
    case "HTML":
      colorClasses = "blue white-text";
      break;
    case "CSS":
      colorClasses = "yellow";
      break;
    case "Bootstrap":
      colorClasses = "teal white-text";
      break;
    case "React":
      colorClasses = "blue lighten-1 white-text";
      break;
    // case "Node.js":
    case "Express":
      colorClasses = "teal darken-2 white-text";
      break;
    case "JavaScript":
      colorClasses = "orange darken-4 white-text";
      break;
    case "jQuery":
      colorClasses = "orange white-text";
      break;
    case "Python":
      colorClasses = "green white-text";
      break;
    case "Laravel":
      colorClasses = "white-text";
      customStyle.backgroundColor = "#FC312D";
      break;
    case "PHP":
      colorClasses = "deep-purple white-text";
      break;
    case "C":
    case "C++":
      colorClasses = "amber";
      break;
    case "Java":
      colorClasses = "red darken-3 white-text";
      break;
    case "JavaFX":
      colorClasses = "red white-text";
      break;
    case "Docker":
      colorClasses = "blue darken-4 white-text";
      break;
    case "Kubernetes":
      colorClasses = "blue lighten-3";
      break;
    case "SQL":
      colorClasses = "blue lighten-2";
      break;
    case "Go":
      colorClasses = "light-blue white-text";
      break;
    case "p5.js":
      colorClasses = "pink accent-3 white-text";
      break;
    default:
      break;
  }

  return (
    <div
      key={language}
      className={`chip hoverable ${colorClasses ? colorClasses : ""}`}
      style={customStyle}
    >
      {language}
    </div>
  );
}
