import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faLink } from "@fortawesome/free-solid-svg-icons";

import React from "react";
import Masonry from "react-masonry-component";

import formatLanguage from "./utility";

export default function ProjectsMasonry({
  projects,
  inFeaturedProjects = false,
}) {
  const languagesOrder = [
    "Docker",
    "Kubernetes",
    "Laravel",
    "HTML",
    "CSS",
    "React",
    "Express",
    "JavaScript",
    "jQuery",
    "PHP",
    "Python",
    "Java",
    "JavaFX",
    "Arduino",
    "C",
    "C++",
    "SQL",
    "Bootstrap",
    "p5.js",
  ];

  function formatLink(link) {
    for (const [website, url] of Object.entries(link)) {
      let color = "";

      switch (website) {
        case "github":
          color = "blue";
          break;
        case "devpost":
          color = "orange accent-2 darken-4";
          break;
        case "demo":
          color = "yellow black-text";
          break;
        case "live":
          color = "green accent-4";
          break;
        default:
          break;
      }

      return (
        <a
          key={url}
          rel="noopener noreferrer"
          href={url}
          className={`btn hoverable waves-effect project-link ${color}`}
          target="_blank"
        >
          {website}
        </a>
      );
    }
  }

  return (
    <Masonry className="row" elementType="div">
      {projects
        .slice()
        .reverse()
        .map(project => (
          <div
            id={`${project.slug}${inFeaturedProjects ? "-f" : ""}`}
            key={`${project.slug}${inFeaturedProjects ? "-f" : ""}`}
            // className={`col s12 ${featuredProjects ? "m6 l6" : "m6 l4"}`}
            className="col s12 m6 l4"
          >
            <div
              className={`card hoverable ${
                project.featured ? "featured-project" : ""
              }`}
            >
              {project.image && (
                <div className="card-image">
                  <a
                    data-fancybox="projects"
                    href={`/assets/projects/${project.image}`}
                  >
                    {project.image.split(".").pop() === "mp4" ? (
                      <video
                        autoPlay
                        muted
                        loop
                        width="100%"
                        src={`/assets/projects/${project.image}`}
                      />
                    ) : (
                      <img
                        src={`/assets/projects/${project.image}`}
                        alt={project.name}
                      />
                    )}
                  </a>
                  {project.screenshots && (
                    <p className="project-screenshots-indicator center-align">
                      1 of {project.screenshots.length + 1}
                    </p>
                  )}
                  {project.screenshots &&
                    project.screenshots.map(screenshot => {
                      for (const [caption, url] of Object.entries(screenshot)) {
                        return (
                          <a
                            key={url}
                            className="hide"
                            data-fancybox="projects"
                            href={`/assets/projects/${url}`}
                            data-caption={caption}
                          >
                            <img
                              src={`/assets/projects/${url}`}
                              alt={caption}
                            />
                          </a>
                        );
                      }
                      return "";
                    })}
                  {project.featured && (
                    <button className="btn-floating halfway-fab waves-effect waves-light amber pulse">
                      <FontAwesomeIcon className="nav-icon" icon={faStar} />
                    </button>
                  )}
                </div>
              )}
              <div
                className={`card-content project ${
                  project.featured ? "blue-grey darken-4 white-text" : ""
                }`}
              >
                <span className="card-title project-name">{project.name}</span>
                <p
                  className={`project-year center-align ${
                    project.featured ? "white-text" : ""
                  }`}
                >
                  {project.year}
                </p>
                <p className="project-description">
                  {project.scriptlet && (
                    <>
                      <span className="scriptlet-label">Scriptlet</span>{" "}
                    </>
                  )}
                  <span
                    dangerouslySetInnerHTML={{ __html: project.description }}
                  ></span>
                </p>
                <div className="project-languages">
                  {/* Sorted Languages */}
                  {project.languages
                    .filter(language => languagesOrder.includes(language))
                    .sort(
                      (a, b) =>
                        languagesOrder.indexOf(a) - languagesOrder.indexOf(b)
                    )
                    .map(language => formatLanguage(language))}
                  {/* Other Languages */}
                  {project.languages
                    .filter(language => !languagesOrder.includes(language))
                    .sort()
                    .map(language => formatLanguage(language))}
                </div>
              </div>
              <div
                className={`project-links card-action ${
                  project.featured ? "blue-grey darken-3" : "grey lighten-5"
                }`}
              >
                <a
                  rel="noopener noreferrer"
                  href={`#${project.slug}${inFeaturedProjects ? "-f" : ""}`}
                  className={`btn-small waves-effect project-link grey lighten-3 black-text`}
                >
                  <FontAwesomeIcon className="nav-icon" icon={faLink} />
                </a>
                {project.links && project.links.map(link => formatLink(link))}
              </div>

              {/* {project.links && (
                <div
                  className={`card-action ${
                    project.featured ? "blue-grey darken-3" : "grey lighten-5"
                  }`}
                >
                  {project.links.map((link) => formatLink(link))}
                </div>
              )} */}
            </div>
          </div>
        ))}
    </Masonry>
  );
}
