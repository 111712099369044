import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

import React, { useEffect } from "react";

import photos from "./data/photography.json";

export default function Photography() {
  useEffect(() => {
    document.title = "Neuton Foo | Photography";
  }, []);

  return (
    <>
      <h1 className="page-title">Photography</h1>
      <div className="content-container card-panel hoverable">
        <p>
          <FontAwesomeIcon className="nav-icon" icon={faInstagram} /> Instagram:{" "}
          <a
            href="https://www.instagram.com/neutonfoo/"
            target="_blank"
            rel="noreferrer"
          >
            https://www.instagram.com/neutonfoo/
          </a>
        </p>
      </div>
      <div className="row">
        {photos.map((photo) => (
          <div className="col s12 m6 xl4" key={photo.image}>
            <div className="card">
              <div className="card-image">
                <a
                  href={`/assets/photography/${photo.image}`}
                  data-fancybox="images"
                  data-caption={photo.caption}
                >
                  <img
                    className="materialboxed"
                    src={`/assets/photography/${photo.image}`}
                    alt={photo.caption}
                  />
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
