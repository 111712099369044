import React, { useEffect } from "react";

export default function Error404() {
  useEffect(() => {
    document.title = "Neuton Foo | Error 404";
  }, []);

  return (
    <>
      <h1 class="page-title">Error 404</h1>
      <div class="content-container card-panel hoverable">Page not found.</div>
    </>
  );
}
